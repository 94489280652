import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { ic_play_arrow } from 'react-icons-kit/md/ic_play_arrow';
// import Logo from 'common/components/UIElements/Logo';
import { openModal, closeModal } from '@redq/reuse-modal';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
// import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import GatsbyImage from 'common/components/GatsbyImage';
import { SectionHeader } from '../appCreative.style';
// import FeatureBlock from 'common/components/FeatureBlock';

import SectionWrapper, {
  ExperienceMainWrap,
  // ExperienceWrapper,
  VideoWrapper,
  VideoArea,
  // ClientWrapper,
  // ImageSlider,
  // ImageSlide,
} from './experience.style';

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube-nocookie.com/embed/KOg1ahGNJAk"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Experiences = () => {
  const data = useStaticQuery(graphql`
    query {
      appCreativeJson {
        experiences {
          title
          slogan
          video_theme {
            childImageSharp {
              gatsbyImageData(
                width: 1230
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  const { slogan, title, video_theme } =
    data.appCreativeJson.experiences;

  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  return (
    <SectionWrapper id="experience">
      <Container>
        <SectionHeader>
          <Heading content={title} />
          <Text content={slogan} />
        </SectionHeader>
        <ExperienceMainWrap>
          <VideoArea onClick={handleVideoModal}>
            <GatsbyImage
              src={
                (video_theme !== null) | undefined
                  ? video_theme.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="videoThumb"
            />
            <Button
              className="video__btn"
              icon={<Icon className="plus" icon={ic_play_arrow} />}
            />
          </VideoArea>
        </ExperienceMainWrap>
      </Container>
    </SectionWrapper>
  );
};

export default Experiences;
