import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { theme } from 'common/theme/appCreative2';
import Seo from 'components/seo';
import AnalyticsTool from 'containers/AppCreative2/AnalyticsTool';
import { ContentWrapper, GlobalStyle } from 'containers/AppCreative2/appCreative2.style';
import Banner from 'containers/AppCreative2/Banner';
// import Cta from 'containers/AppCreative2/CTA';
// import EverNeed from 'containers/AppCreative2/EverNeed';
// import Faq from 'containers/AppCreative2/FAQ';
// import Footer from 'containers/AppCreative2/Footer';
import Navbar from 'containers/AppCreative2/Navbar';
// import PricingPolicy from 'containers/AppCreative2/PricingPolicy';
// import Technology from 'containers/AppCreative2/Technology';
// import WalletExperience from 'containers/AppCreative2/WalletExperience';
// import WhyYouChoose from 'containers/AppCreative2/WhyYouChoose';
import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';

import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import Experiences from 'containers/AppCreative/Experience';
import Footer from 'containers/WebApp/Footer';

import CookieConsent from 'react-cookie-consent';

const AppCreativeTwo = () => {
  return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <Seo title="Fortune Mine Games | We build long-lasting social & casual mobile games." />
          <Modal />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <Banner />
            <Experiences />
            {/*<WhyYouChoose />*/}
            <AnalyticsTool />
            {/*<EverNeed />*/}
            {/*<WalletExperience />*/}
            {/*<Technology />*/}
            {/*<div className='app_creative_combined_section_wrapper'>*/}
            {/*  <PricingPolicy className="pricing_policy" />*/}
            {/*  <div className='app_creative_dark_section app_creative_section' style={{backgroundColor: `${theme?.colors?.dark}`}}>*/}
            {/*    <Faq className="faq_section" />*/}
            {/*    <Cta className="cta_section" />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <Footer />
            <CookieConsent
                location="bottom"
                buttonText="Okay"
                //declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-analytics">
              We use necessary cookies to make our site work.
            </CookieConsent>
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
  );
};
export default AppCreativeTwo;
